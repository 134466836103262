<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      userinfo: [],
      items: [],
      micoappstatus: false,
      activeName: "setting",
      config: {
        "points": {
          "points_name": "",
          "describe": ""
        },
        "recharge": {
          "is_entrance": 0,
          "is_custom": 0,
          "describe": ""
        },
        "register": {
          "isOauthMpweixin": 0
        }
      },
      saveloading: false,
    };
  },
  mounted() {
    this.title = this.$t("menuitems.wechat.micoapp.text");
    this.items = [
      {
        text: this.$t("menuitems.wechat.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.wechat.micoapp.text"),
        active: true,
      },
    ];
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    if (this.userinfo.topuid >= 1) {
      this.block = true;
    }
    //   this.page.title=this.$t('menuitems.devices.lists')
    this.getMicoapp();
    this.getMicoappConfig();
  },
  methods: {
    getMicoapp() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getmicoappapi",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          if (response.data.status == 200) {
            that.micoappstatus = true;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleClick(tab, event) {
      
    },
    getMicoappConfig(){
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getmicoappsetting",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          if (response.data.status == 200) {
            that.config = response.data.config;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    saveconfig(){
      var that = this;
      that.saveloading = true
      that.$axios
        .post(
          that.apiuri,
          {
            action: "savemicoappsetting",
            config: that.config
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.saveloading = false
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-if="micoappstatus">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane :label="$t('menuitems.wechat.micoapp.setting')" name="setting">
                <el-form ref="form" :model="config" label-width="160px" v-loading="saveloading">
                  <el-divider>{{ $t("menuitems.wechat.micoapp.register") }}</el-divider>
                  <el-form-item :label="$t('menuitems.wechat.micoapp.isOauthMpweixin')">
                    <el-switch
                     v-model="config.register.isOauthMpweixin"
                     :active-value="1"
                     :inactive-value="0"></el-switch>
                  </el-form-item>
                  <el-divider>{{ $t("menuitems.wechat.micoapp.chargesetting") }}</el-divider>
                  <el-form-item :label="$t('menuitems.wechat.micoapp.is_entrance')">
                    <el-switch
                     v-model="config.recharge.is_entrance"
                     :active-value="1"
                     :inactive-value="0"></el-switch>
                  </el-form-item>
                  <el-form-item :label="$t('menuitems.wechat.micoapp.is_custom')">
                    <el-switch
                     v-model="config.recharge.is_custom"
                     :active-value="1"
                     :inactive-value="0"></el-switch>
                  </el-form-item>
                  <el-form-item :label="$t('menuitems.wechat.micoapp.describe')">
                    <el-input type="textarea" v-model="config.recharge.describe"></el-input>
                  </el-form-item>
                  <el-divider>{{ $t("menuitems.wechat.micoapp.pointsetting") }}</el-divider>
                  <el-form-item :label="$t('menuitems.wechat.micoapp.pointsname')">
                    <el-input v-model="config.points.points_name"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('menuitems.wechat.micoapp.pointsdescribe')">
                    <el-input type="textarea" v-model="config.points.describe"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="saveconfig">{{ $t("setting.system.network.save") }}</el-button>
                  </el-form-item>
                </el-form>
                

              </el-tab-pane>
              <el-tab-pane :label="$t('menuitems.wechat.micoapp.showapp')" name="showapp">
              
              </el-tab-pane>
              <el-tab-pane :label="$t('menuitems.wechat.micoapp.orders')" name="orders">
              
              </el-tab-pane>
              <el-tab-pane :label="$t('menuitems.wechat.micoapp.charge')" name="charge">
                
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="card-body" v-else>
            <el-empty
              :description="$t('menuitems.wechat.micoapp.notexit')"
            ></el-empty>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>